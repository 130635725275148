<script>
import NavigationAdmin from '@/components/Admin/Navigation.vue'
import mix from '@/mixins/mix'
import axios from 'axios'
import moment from 'moment'
export default {
    mixins: [mix],
    components: {
        NavigationAdmin
    },
    data() {
        return {
            pelamarStaff: [],
            pelamarMagang: [],
            headers: [
                { text: 'Kode Lamaran', value: 'kode' },
                { text: 'Jumlah Pelamar', value: 'count' },
                { text: 'Actions', value: 'actions'}
            ],
            headersDetailLowonganStaff: [
                { text: 'No', value: 'no'},
                { text: 'CV', value: 'cv'},
                { text: 'Tanggal', value: 'updatedAt'},
                { text: 'Aksi', value: 'actions'}
            ],

            headersDetailLowonganMagang: [
                { text: 'No', value: 'no'},
                { text: 'CV', value: 'cv'},
                { text: 'Transkrip Nilai', value: 'transkip_nilai'},
                { text: 'Tanggal', value: 'updatedAt'},
                { text: 'Aksi', value: 'actions'}
            ],

            dialogStaff: false,
            dialogMagang: false,
            detailLowongan: []
        }
    },
    created() {
        this.getPelamar()
    },
    methods: {
        async getPelamar() {
            try {
                const { data } = await axios.get(`${this.apibe}lowongan_magang/groupby-kode`)
                this.pelamarMagang = data

                await axios.get(`${this.apibe}lowongan_staff/groupby-kode`)
                    .then(res => {
                        this.pelamarStaff = res.data
                    })
            } catch (error) {
                console.log(error)
            }
        },

        async getDetailStaff(kode) {
            try {
                const { data } = await axios.get(`${this.apibe}lowongan_staff/file/${kode}`)
                this.detailLowongan = data
                this.dialogStaff = true
            } catch (error) {
                console.log(error)
            }
        },
        
        async getDetailMagang(kode) {
            try {
                const { data } = await axios.get(`${this.apibe}lowongan_magang/file/${kode}`)
                this.detailLowongan = data
                this.dialogMagang = true
            } catch (error) {
                console.log(error)
            }
        },

        splitNameFile(url){
            const split = url.split('/')
            return split[split.length - 1]
        },
        moment(date){
            const dates = moment(date).lang('id').format('LL')
            const time = moment(date).lang('id').format('LT')
            return `${dates} - ${time}`
        },

        toFile(file){
            return this.assets + file
        },

        deleteFileStaff(item){
            const { id_lowongan } = item
this.$swal({
                    title: 'Are you sure to delete this data?',
                    text: 'You will not be able to recover this data!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
            try {
                axios.delete(`${this.apibe}lowongan_staff/file/${id_lowongan}`, {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.token}`
                    }
                }).then(res => {
                    this.$swal('Berhasil', 'File berhasil dihapus', 'success')
                    this.getPelamar()
                    this.dialogStaff = false
                })
            } catch (error) {
                console.log(error)
                this.$swal('Gagal', 'File gagal dihapus', 'error')
            }
                    }
                })
        },

        deleteFileMagang(item){
            const { id_lowongan } = item
this.$swal({
                    title: 'Are you sure to delete this data?',
                    text: 'You will not be able to recover this data!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
            try {
                axios.delete(`${this.apibe}lowongan_magang/file/${id_lowongan}`, {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.token}`
                    }
                }).then(res => {
                    this.$swal('Berhasil', 'File berhasil dihapus', 'success')
                    this.getPelamar()
                    this.dialogMagang = false

                })
            } catch (error) {
                console.log(error)
                this.$swal('Gagal', 'File gagal dihapus', 'error')
            }
                    }
                })
        }
    }
}
</script>
<template>
    <v-app>
        <navigation-admin />
        <v-card class="my-16">
            <v-card-title>
                <h2 class="headline">Pelamar Staff</h2>
            </v-card-title>
            <v-data-table
            :items="pelamarStaff"
            :headers="headers"
            >
            <template v-slot:item.count="{ item }">
                {{ item.count }} Pelamar
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn
                color="primary"
                class="mr-4"
                @click="getDetailStaff(item.kode)"
                >
                Detail
                </v-btn>
            </template>
            </v-data-table>
        </v-card>

        <v-card class="my-16">
            <v-card-title>
                <h2 class="headline">Pelamar Magang</h2>
            </v-card-title>
            <v-data-table
            :items="pelamarMagang"
            :headers="headers"
            >
            <template v-slot:item.count="{ item }">
                {{ item.count }} Pelamar
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn
                color="primary"
                class="mr-4"
                @click="getDetailMagang(item.kode)"
                >
                Detail
                </v-btn>
            </template>
            </v-data-table>
        </v-card>

        <v-dialog v-model="dialogStaff" max-width="800">
            <v-card>
                <v-card-title class="headline">Detail Pelamar</v-card-title>
                <v-card-text>
                    <v-data-table
                    :items="detailLowongan"
                    :headers="headersDetailLowonganStaff"
                    >
                    <template v-slot:item.no="{ item, index }">
                        {{ index + 1 }}
                    </template>
                    <template v-slot:item.updatedAt="{ item }">
                        {{ moment(item.updatedAt) }}
                    </template>
                    <template v-slot:item.cv="{ item }">
                        <a :href="toFile(item.cv)" target="_blank">{{ splitNameFile(item.cv) }}</a>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon
                        color="red"
                        @click="deleteFileStaff(item)"
                        >
                        mdi-delete
                        </v-icon>
                    </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogMagang" max-width="800">
            <v-card>
                <v-card-title class="headline">Detail Pelamar</v-card-title>
                <v-card-text>
                    <v-data-table
                    :items="detailLowongan"
                    :headers="headersDetailLowonganMagang"
                    >
                    <template v-slot:item.no="{ item, index }">
                        {{ index + 1 }}
                    </template>
                    <template v-slot:item.updatedAt="{ item }">
                        {{ moment(item.updatedAt) }}
                    </template>
                    <template v-slot:item.cv="{ item }">
                        <a :href="toFile(item.cv)" target="_blank">{{ splitNameFile(item.cv) }}</a>
                    </template>
                    <template v-slot:item.transkip_nilai="{ item }">
                        <a :href="toFile(item.transkip_nilai)" target="_blank">{{ splitNameFile(item.transkip_nilai) }}</a>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon
                        color="red"
                        @click="deleteFileMagang(item)"
                        >
                        mdi-delete
                        </v-icon>
                    </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>
</template> 